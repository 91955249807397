<template>
  <div style="width:100%;background: white;">
      <vue-good-table
        @on-selected-rows-change="selectionChanged"
              :columns="columns"
              :rows="groupUsers"
               styleClass="vgt-table striped"
               :select-options="{
                enabled: true,
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                selectOnCheckboxOnly: true,
              }"
              :search-options="{
                enabled: true,
                searchFn: mySearchFn,
                placeholder: 'Search this table',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 50,
              }"
              v-bind:class="{ 'cancelled': cancelledPipe }"
            >
            <template slot="table-column" slot-scope="props">
              <span v-if="props.column.label =='fullName'">
                  Name
               </span>
               <span v-else-if="(props.column.label =='Shift Preference')" style="padding:0px;">

                  <div v-if="showShiftPreference == true">
                    Shift Preference
                  </div>
                  
               </span>
               <span v-else-if="(props.column.label =='Position Preference')" style="padding:0px;"> 

                  <div v-if="showShiftPreference == true" style="min-width:140px;">
                    Position Preference
                  </div>
                  
               </span>


               <span v-else-if="(props.column.label =='Pipeline')">

                  <div v-if="showShiftPreference == true">
                  </div>
                  
               </span>

               <span v-else-if="(props.column.label =='Action')">

                  <div v-if="action && (action == 'applicants' || action == 'removeUser')">
                    Action
                  </div>
                  
               </span>
               <span v-else-if="(props.column.label =='Notes')" style="padding:0px;">

                  <div v-if="showShiftPreference == true">
                    Notes
                  </div>
                  
               </span>
               <span v-else-if="(props.column.label =='Est. Start Date')" style="padding:0px;">

                  <div v-if="showShiftPreference == true">
                    Est. Start Date
                  </div>
                  
               </span>
               <span v-else-if="(props.column.label =='Status')">

                  <div v-if="!showShiftPreference">
                    Status
                  </div>
                  
               </span>
               <!-- <span v-else-if="(props.column.label =='Location')">

                  <div v-if="showLocation">
                    Location
                  </div>
                  
               </span> -->
               <span v-else-if="(props.column.label =='Assignment')">

                  <div v-if="!showShiftPreference">
                    Assignment
                  </div>
                  
               </span>
               <!-- <span v-else-if="props.column.field == 'watchlist'">
                <div style="width: 100%;" class="text-center">
                  <i class="fa-regular fa-star"></i>
                </div>
              </span> -->
               <span v-else>
                  {{props.column.label}}
               </span>
            </template>
            <div slot="selected-row-actions">

              <button class="btn btn__small btn__dark mr-4 mt-1" @click="exportSelected()">
                Export <i class="fa-regular fa-arrow-up-right-from-square ml-2"></i>
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>

              
              <button v-show="!showTextBox" class="btn btn__small btn__dark mr-4 mt-1" @click="showBox()">
                Send Message <i class="fa-solid fa-comment-sms ml-2"></i>
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
              <button v-show="showTextBox" class="btn btn__small btn__dark mr-4 mt-1" @click="hideBox()">
                Hide Message <i class="fa-solid fa-comment-sms ml-2"></i>
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
              
              
              <transition name="fade">
                <div class="pa-3" v-if="showTextBox">
                  <textarea id="message" class="mt-3" cols="30" rows="4" style="min-width: 300px;" v-model="textMessage"></textarea>
                  
                  <!-- <input type="text" placeholder="Type Message" v-model="textMessage" style="min-width: 300px;" /> -->
                  <button v-if="textMessage" class="btn btn__primary btn__small mt-3 mb-3" @click="sendTextMessage()">Send
                    <transition name="fade">
                      <span class="ml-2" v-if="performingRequest3">
                      <i class="fa fa-spinner fa-spin"></i>
                      </span>
                    </transition>
                  </button>
                </div>
              </transition>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'photoUrl'" style="padding-left:20px;">
                <span>
                  <img :src="(props.row.photoUrl || `https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/avatar%20copy.png?alt=media&token=966c07c4-125a-490f-81be-4e2d26bf33fa`)" alt="" style="width: 8rem; height:8rem; border-radius: 50%; padding: 0.25rem;">
                </span>
              </span>
              <span v-else-if="props.column.field == 'fullName'">
                <span class="flex">
                  <router-link :to="'/users/' + props.row.id" class="darkLink">
                    <h5 v-if="!props.row.blacklist || (props.row.blacklist && props.row.blacklist.length == 0)">
                    {{props.row.firstName}} {{props.row.lastName}}
                    </h5>
                    <h5 v-if="props.row.blacklist && props.row.blacklist.length >= 1" class="danger">
                    {{props.row.firstName}} {{props.row.lastName}}
                    </h5>
                  </router-link>
                </span>

                <div class="mt-0 caption strong" v-if="props.row && props.row.employmentStatus">
                <span v-if="props.row.employmentStatus == 'Active'" class="success">{{props.row.employmentStatus}}</span>
              </div>
              <div class="mt-0 caption strong" v-if="props.row && props.row.employmentStatus">
                <span v-if="props.row.employmentStatus == 'Onboarding'" class="warning">{{props.row.employmentStatus}}</span>
              </div>
              <div class="mt-0 caption strong" v-if="props.row && props.row.employmentStatus">
                <span v-if="props.row.employmentStatus == 'Terminated'" class="danger">{{props.row.employmentStatus}}</span>
              </div>

                

                <div class="caption mt-1 mb-0 iconRow">



                  <button :disabled="cancelledPipe" v-if="isWatchList(props.row)" class="btn btn__icon" @click="removeWatch(props.row)"><i class="fa-solid fa-star warning"></i></button>
                 <button :disabled="cancelledPipe" v-if="!isWatchList(props.row)" class="btn btn__icon" @click="addWatch(props.row)"><i class="fa-regular fa-star" style="opacity: 0.6;"></i></button>

                  <span v-if="props.row.beeline"><i class="fa-duotone fa-bee" style="--fa-primary-color: #6b6b6b; --fa-secondary-color: #fff700; --fa-secondary-opacity: 0.8;"></i></span>

                  <span>
                    <button v-if="!props.row.hot" @click="makeHot(props.row)" class="btn btn__icon">
                      <i class="fa-solid fa-fire-flame" style="opacity: 0.4;"></i>
                    </button>
                    <button v-if="props.row.hot" @click="makeCold(props.row)" class="btn btn__icon">
                      <i class="fa-solid fa-fire-flame" style="color:#E25822;"></i>
                    </button>
                  </span>

                  <span v-if="props.row && props.row.isNY">
                    
                    <i class="fa-solid fa-pizza-slice" style="color: #F46E05;"></i>
                  </span>

                  <span v-if="props.row.lead"><i class="fa-solid fa-badge-check blueHue"></i></span>
                  

                  <span v-if="props.row.groups && props.row.groups.length >= 1">
                    <v-popover @apply-hide="clearPopover()" @apply-show="showPopover(props.row.groups)">
                    <i class="fa-solid fa-user-group blueHue"></i>
                    <template slot="popover" style="display: inline;">
                      Member Of:
                      <div v-for="(item, index) in activeTitles">
                        {{item}} 
                      </div>
                      </template>
                    </v-popover>
                  </span>

                  <span v-if="props.row.groupApplications && props.row.groupApplications.length >= 1">
                    <v-popover @apply-hide="clearPopover()" @apply-show="showPopover(props.row.groupApplications)">
                    <i class="fa-solid fa-user-group orangeHue"></i>
                    <template slot="popover" style="display: inline;">
                      Applied To:
                      <div v-for="(item, index) in activeTitles">
                        {{item}} 
                      </div>
                      </template>
                    </v-popover>
                  </span>

                  <span v-if="props.row.id_card_path">
                    <v-popover>
                      <i class="fa-solid fa-address-card" style="color:#333333;"></i>
                      <template slot="popover" style="display: inline;">
                        <img :src="props.row.id_card_path" alt="" style="width:100%;">
                      </template>
                    </v-popover>
                  </span>

                  <span v-if="props.row.picture">
                    <a :href="props.row.picture" target="_blank">
                      <i class="fa-regular fa-file" style="color:#333333;"></i>
                    </a>
                  </span>

         
                <span v-if="props.row.pipeline && props.row.pipeline.length >= 1">
                  <v-popover @apply-hide="clearPipover()" @apply-show="showPipelines(props.row.pipeline)">
                    <i class="fa-solid fa-filter" style="color:#1F966F;"></i>
                    <template slot="popover" style="display: inline;">
                      Pipelines:
                      <div v-for="(item, index) in activePipelines">
                        {{item}} 
                      </div>
                    </template>
                  </v-popover>
                </span>


                  <!-- <span v-if="props.row.pipeline && props.row.pipeline.length >= 1">
                    <v-popover>
                      <i class="fa-solid fa-filter" style="color:#1F966F;"></i>
                      <template slot="popover" style="display: inline;">
                        <span v-for="(item, index) in props.row.pipeline">
                          {{item.title}}
                        </span>
   
                      </template>
                    </v-popover>
                  </span>

                  <span v-if="props.row.pipelineOrder">
                    <v-popover>
                      <i class="fa-solid fa-filter" style="color:#1F966F;"></i>
                      <template slot="popover" style="display: inline;">
                        <span v-if="props.row.pipelineOrder.title">
                          {{props.row.pipelineOrder.title}}
                        </span>
                      </template>
                    </v-popover>
                  </span> -->

                  <span v-if="props.row && props.row.isNy && props.row.isNy == 'true'">

                    sdg
                    <i class="fa-solid fa-pizza-slice"></i>
                  </span>

                  <span v-if="props.row.touchLog && props.row.touchLog[0]">
                    <v-popover>
                      <i class="fa-light fa-hand-point-up blueHueDark"></i>
                      <template slot="popover">
                        <div>{{findLast(props.row.touchLog).contactedByFirstName}} {{findLast(props.row.touchLog).contactedByLastName}}, {{findLast(props.row.touchLog).method}}:</div>
                        <div>{{findLast(props.row.touchLog).notes}}</div>
                      </template>
                    </v-popover>
                  </span>

                </div>
                <div class="mt-1">
                  <star-rating :read-only="true" :star-size="12" v-if="props.row && props.row.rating" v-model="props.row.rating" class="caption"></star-rating>
                </div>

                <div class="flex justify-flex-start mt-0">
                  <span v-if="props.row && (!props.row.touchLog || props.row.touchLog.length == 0)" class="caption flex align-center">
                    <button class="btn btn__outlined btn__xsmall" @click="initialContact(props.row)">Initial Contact Made</button>
                  </span>
                </div>

                
              <div class="mt-0 caption" v-if="props.row.jumpHireDate" style="color: #21bcf3;">Start Date: {{props.row.jumpHireDate | moment("MM/DD/YYYY")}}</div>

              <div class="mt-0 caption" v-if="props.row.jumpTerminationDate" style="color:#ef4b46;">End Date: {{props.row.jumpTerminationDate | moment("MM/DD/YYYY")}}</div>

                <div class="mt-1 mb-1 caption" v-if="props.row && props.row">
                  <InterviewStatus :user="props.row" />
                </div>





                
                <!-- <div class="flex justify-flex-start mt-0">
                  <span v-if="props.row && props.row.points" class="caption flex align-center">
                    {{props.row.points}} Points
                  </span>
                </div>
                <div class="flex justify-flex-start mt-0 caption">
                  DOB: {{props.row.dob | moment("MM/DD/YYYY") }}
                </div> -->
              


                <!-- <div class="mt-0 caption" v-if="props.row.hireDate" style="color: #21bcf3;">HD: {{props.row.hireDate | moment("MM/DD/YYYY")}}</div>
                <div class="mt-0 caption" v-if="props.row.terminationDate" style="color:#ef4b46;">TD: {{props.row.terminationDate | moment("MM/DD/YYYY")}}</div> -->
                
                <div class="caption mt-1" v-if="props.row.positionPreference && props.row.positionPreference.length >= 1">
                  Position Preferences: <button class="flatChip mr-1" v-for="(item, index) in props.row.positionPreference">{{item.title}}</button>
                </div>
                <div class="caption mt-1" v-if="props.row.positionPreference && !props.row.positionPreference.length">
                Position Preferences: <button class="flatChip mr-1">{{props.row.positionPreference.title}}</button>
              </div>

              <div class="caption mt-1" v-if="(props.row && props.row.logins && props.row.logins.length > 0)">Last Login: {{ lastLoginTime(props.row) }}</div>
   

                <button v-if="(action && action == 'groupUser') && (!props.row.pipeline || (props.row.pipeline && props.row.pipeline.length == 0))" class="btn btn__xsmall btn__outlined mt-1 pb-2" @click="addPipeline(props.row)">Add to Pipeline <i class="fa-light fa-filter ml-2"></i></button>

              </span>
              <span v-else-if="props.column.field == 'created'" class="caption">
                {{formatDate(props.row.created)}}
              </span>
              <span v-else-if="(props.column.field == 'estimatedStartDate') && (isPipeline == true || (showShiftPreference == true))">
           
                <div style="padding: 0.75em 0.75em 0.75em 0.75em;" v-if="showShiftPreference == true">

                  <input type="date" v-model.trim="props.row.estimatedStartDate" id="estimatedStartDate" @change="updatePipe(props.row)" :readonly="cancelledPipe" />
                </div>
              </span>
              <span v-else-if="(props.column.field == 'estimatedStartDate') && (!isPipeline && !showShiftPreference)">
              </span>
              <span v-else-if="(props.column.field == 'notes') && (isPipeline || showShiftPreference == true)">
                <span style="padding: 0.75em 0.75em 0.75em 0.75em;">
                  <button :disabled="cancelledPipe" v-show="!props.row.pipelineNote" class="btn btn__flat btn__icon" @click="showNote(props.row)" v-tooltip="'Leave a note'"><i class="far fa-sticky-note ml-3 mr-3" style="opacity:0.5;"></i></button>
                  <button :disabled="cancelledPipe" v-show="props.row.pipelineNote" class="btn btn__flat btn__icon" @click="showNote(props.row)" v-tooltip="'Leave a note'"><i class="far fa-sticky-note ml-3 mr-3" style="color:blue"></i></button>
                  <transition name="modal">
                    <div v-if="activeItem == props.row">
                      <PipelineNote :item="props.row" @close="closeNote(props.row)" />
                    </div>
                  </transition>
                </span>
              </span>
              <span v-else-if="props.column.field == 'position' && ((!action || (action != 'removePipeline')) && showShiftPreference == true)">

                <div style="width:calc(160px + 1.5em); padding: 0.75em 0.75em 0.75em 0.75em;" v-if="props.row.positionPreference && props.row.positionPreference[0]">
                  {{props.row.positionPreference[0].title}}
                </div>

                
              </span>
              <span v-else-if="props.column.field == 'position' && action && (action == 'removePipeline' )">
                <div style="width:calc(200px + 1.5em); padding: 0.75em 0.75em 0.75em 0.75em;" >

                  <!-- <div style="width:calc(160px + 1.5em); padding: 0.75em 0.75em 0.75em 0.75em;" v-if="props.row.positionPreference && props.row.positionPreference[0]">
                  {{props.row.positionPreference[0].title}}
                </div> -->

                  <div v-if="group && group.jobs">
                    <v-select
                      :disabled="(!group || (group && group.jobs &&group.jobs.length == 0))"
                      class="mt-2"
                      label="title" 
                      :options="group.jobs"
                      v-model="props.row.positionPreference"
                      
                      multiple
                      taggable
                      push-tags                    
                      :id="props.row.id + `pipelinePosition`"
                      @input="updatePosition(props.row)"

                      >
                    </v-select>
                  </div>
                </div>
              </span>
              <span v-else-if="props.column.field == 'shift' && action && action == 'removePipeline'">
                <div style="width:calc(200px + 1.5em); padding: 0.75em 0.75em 0.75em 0.75em;" v-if="showShiftPreference">

                  <div>


                    <v-select
                      :disabled="!eventShifts || eventShifts.length == 0"
                      class="mt-2"
                      label="title" 
                      :options="eventShifts"
                      v-model="props.row.shiftPreference"
                      :clearable=true
                      taggable
                      multiple
                      clearable
                      push-tags
                      :id="props.row.id + `pipelineShifts`"
                      @input="updatePreference(props.row)"
                      >
                      <template #option="{ title, poNumber }">
                        <div>{{ title }} // {{poNumber}}</div>
                        <!-- <em>{{ author.firstName }} {{ author.lastName }}</em> -->
                      </template>
                    </v-select>
    
                  </div>
                </div>
              </span>
              <span v-else-if="props.column.field == 'shift' && (!action || action != 'removePipeline')">

                <div style="width:calc(200px + 1.5em); padding: 0.75em 0.75em 0.75em 0.75em;" v-if="showShiftPreference">

                  <div>


                    <v-select
                      :disabled="!eventShifts || eventShifts.length == 0"
                      class="mt-2"
                      label="title" 
                      :options="eventShifts"
                      v-model="props.row.shiftPreference"
                      :clearable=true
                      taggable
                      clearable
                      multiple
                      push-tags
                      :id="props.row.id + `pipelineShifts`"
                      @input="updatePreference(props.row)"
                      >
                      <template #option="{ title, poNumber }">
                        <div>{{ title }} // {{poNumber}}</div>
                        <!-- <em>{{ author.firstName }} {{ author.lastName }}</em> -->
                      </template>
                    </v-select>
    
                  </div>
                </div>



                <!-- <div style="width: 160px;" v-if="props.row.shiftPreference && props.row.shiftPreference[0]">
                  {{props.row.shiftPreference[0].title}}
                </div> -->

                
              </span>
              <span v-else-if="props.column.field == 'onboardFlow'">
                <!-- <div class="caption">
                  <ContractorStatus :user="props.row" />
                 
                </div> -->
                <div class="caption">
                  <EmployeeStatus :user="props.row" />
                 
                </div>
              </span>
              <span v-else-if="props.column.field == 'check'">

                

                
                <div class="caption" v-if="(props.row.checkInvitations && props.row.checkInvitations[0] && props.row.checkInvitations[0].payload && props.row.checkInvitations[0].payload.applicantinfo) && !props.row.backgroundCheckResponse">{{props.row.checkInvitations[0].payload.applicantinfo.reference}} - 
                  <span v-if="props.row.checkInvitations && props.row.checkInvitations[0] && props.row.checkInvitations[0].payload && props.row.checkInvitations[0].payload.result">
                    {{props.row.checkInvitations[0].payload.result.status}} <span v-if="props.row.checkInvitationResponses && props.row.checkInvitationResponses[0] && props.row.checkInvitationResponses[0].payload">
                       on 
                       <span v-if="props.row.checkInvitationResponses[0].payload[0] && props.row.checkInvitationResponses[0].payload[0].DateCreated">{{props.row.checkInvitationResponses[0].payload[0].DateCreated | moment("MM/DD/YYYY")}}</span>
                    </span>
                  </span>
                  <div class="mt-0">
                    <button :disabled="cancelledPipe" class="btn btn__xsmall btn__outlined mt-2" @click="onTextBack(props.row)">
                      Send Reminder
                      <transition name="fade">
                        <span class="ml-2" v-if="performingRequestBack">
                        <i class="fa fa-spinner fa-spin"></i>
                        </span>
                      </transition>
                    </button>
                  </div>
                  <div class="mb-0 mt-2" v-if="props.row.updateBackgroundReminderSent">
                    Reminder Sent: {{formatStamp(props.row.updateBackgroundReminderSent)}}

                    <!-- {{props.row.updateBackgroundReminderSent | moment("MMMM Do YYYY, h:mm a") }} -->
                  </div>

                </div>

                <div v-if="props.row.checkOrderResults && (props.row.checkOrderResults[0]  && props.row.checkOrderResults[0].payload) && !props.row.backgroundCheckResponse">
                  <router-link :to="'/users/' + props.row.id + '/backgroundcheck'">
                    <button class="btn btn__xsmall btn__primary mt-2">Review Results</button>
                  </router-link>

                  

                </div>
 

                <div v-if="props.row.backgroundCheckResponse">
                  <div class="caption strong">Response: {{props.row.backgroundCheckResponse}}</div>
                </div>

              </span>
              <span v-else-if="props.column.field == 'drug'">

                

                <div class="caption" v-if="(props.row.drugInvitations && props.row.drugInvitations[0] && props.row.drugInvitations[0].payload && props.row.drugInvitations[0].payload.applicantinfo) && !props.row.drugCheckResponse">{{props.row.drugInvitations[0].payload.applicantinfo.reference}} - 
                  <span v-if="props.row.drugInvitations && props.row.drugInvitations[0] && props.row.drugInvitations[0].payload && props.row.drugInvitations[0].payload.result">
                    {{props.row.drugInvitations[0].payload.result.status}} <span v-if="props.row.drugInvitationResponses && props.row.drugInvitationResponses[0] && props.row.drugInvitationResponses[0].payload">
                       on 
                       <span v-if="props.row.drugInvitationResponses[0].payload[0] && props.row.drugInvitationResponses[0].payload[0].DateCreated">{{props.row.drugInvitationResponses[0].payload[0].DateCreated | moment("MM/DD/YYYY")}}</span>
                    </span>
                  </span>

                  <div class="mt-0">
                    <button :disabled="cancelledPipe" class="btn btn__xsmall btn__outlined mt-2" @click="onTextDrug(props.row)">
                      Send Reminder
                      <transition name="fade">
                        <span class="ml-2" v-if="performingRequestDrug">
                        <i class="fa fa-spinner fa-spin"></i>
                        </span>
                      </transition>
                    </button>
                  </div>
                  <div class="mb-0 mt-2" v-if="props.row.updateDrugReminderSent">
                    Reminder Sent: {{formatStamp(props.row.updateDrugReminderSent)}}

                    <!-- {{props.row.updateDrugReminderSent | moment("MMMM Do YYYY, h:mm a") }} -->
                  </div>

                </div>

                <div v-if="props.row.drugOrderResults && (props.row.drugOrderResults[1] && props.row.drugOrderResults[1].payload) && !props.row.drugCheckResponse">
                  <router-link :to="'/users/' + props.row.id + '/backgroundcheck'">
                    <button class="btn btn__xsmall btn__primary mt-2">Review Response</button>
                  </router-link>
                </div>


                <div v-if="props.row.drugCheckResponse">
                  <div class="caption strong">Response: {{props.row.drugCheckResponse}}</div>
                </div>

                


              </span>
              <span v-else-if="props.column.field == 'accurate'">
                <div class="caption mb-1" v-if="(props.row && props.row.accurateCheckCreated && props.row.accurateCheckCreated != null) && (!props.row.accurateCheckResponse || props.row.accurateCheckResponse == 'Order Placed')">

                  Ordered: {{formatStamp(props.row.accurateCheckCreated)}}

                </div>
                <div class="caption strong" v-bind:class="{ danger: props.row.accurateCheckResponse == 'Failed Do Not Hire' }" v-if="props.row.accurateCheckResponse">Status: {{props.row.accurateCheckResponse}}
                </div>


                <div class="mt-0" v-if="(props.row.accurateCheckResponse == 'Order Placed')">
                  <button :disabled="cancelledPipe" class="btn btn__xsmall btn__outlined mt-2" @click="onTextAccurate(props.row)">
                    Send Reminder
                    <transition name="fade">
                      <span class="ml-2" v-if="performingRequestAccurate">
                      <i class="fa fa-spinner fa-spin"></i>
                      </span>
                    </transition>
                  </button>
                </div>
                <div class="caption mb-0 mt-2" v-if="props.row.updateAccurateReminderSent">
                    Reminder Sent: {{formatStamp(props.row.updateAccurateReminderSent)}}


                    <!-- {{props.row.updateAccurateReminderSent | moment("MMMM Do YYYY, h:mm a") }} -->
                </div>
                <div class="caption mb-1 mt-1" v-if="(props.row && props.row.accurateCheckRef)">
                  #{{props.row.accurateCheckRef}}
                </div>
              </span>
              <span v-else-if="props.column.field == 'amazon'">
                
                
                <div class="caption mb-1" v-if="(props.row && props.row.amazonCheckCreated && props.row.amazonCheckCreated != null) && (!props.row.amazonCheckResponse || props.row.amazonCheckResponse == 'Order Placed')">

                  <!-- Check Ordered: {{props.row.amazonCheckCreated.toDate() | moment("MM/DD/YYYY")}} -->

                Ordered: {{formatStamp(props.row.amazonCheckCreated)}}
                </div>
                <div class="caption strong" v-bind:class="{ danger: props.row.amazonCheckResponse == 'Failed Do Not Hire' }" v-if="props.row.amazonCheckResponse">Status: {{props.row.amazonCheckResponse}}</div>
              </span>
              <!-- <span v-else-if="props.column.field == 'watchlist'">
                 <button :disabled="cancelledPipe" v-if="isWatchList(props.row)" class="btn btn__icon" @click="removeWatch(props.row)"><i class="fa-solid fa-star warning"></i></button>
                 <button :disabled="cancelledPipe" v-if="!isWatchList(props.row)" class="btn btn__icon" @click="addWatch(props.row)"><i class="fa-regular fa-star" style="opacity: 0.6;"></i></button>
              </span> -->
              
              <span v-else-if="props.column.field == 'employmentStatus'">

                <div style="width: 100px;" v-if="!showShiftPreference">

                  <span class="caption" v-if="props.row.employmentStatus">{{props.row.employmentStatus}}</span>
                </div>
              </span>
              <span v-else-if="props.column.field == 'employmentAssignment'">
                <div style="width: 160px;">
                  <span class="caption" v-if="!props.row.employmentAssignment && props.row.pipelineShifts && props.row.pipelineShifts[0] && props.row.pipelineShifts[0].eventInfo">
                    <router-link :to="'/orders/' + props.row.pipelineShifts[0].eventInfo.id + '/staffing/pipeline'">
                      {{props.row.pipelineShifts[0].eventInfo.title}}
                    </router-link>
                  
                </span>
                </div>
                <div style="width: 160px;" v-if="!showShiftPreference">

                  <span class="caption" v-if="props.row.employmentAssignment">
                    <router-link :to="'/orders/' + props.row.employmentAssignment.eventId + '/staffing/pipeline'">
                      {{props.row.employmentAssignment.eventName}}
                    </router-link>
                  
                </span>
                </div>
              </span>
              <span v-else-if="props.column.field == 'onboardStatus'">
                <div v-if="props.row.workerId && props.row.evereeOnboardingComplete" class="caption" style="color:#00C897;">
                  Contractor
                </div>
                <div v-if="props.row.workerId && !props.row.evereeOnboardingComplete" class="caption" style="color:#f0ad4e;">
                  Contractor 
                </div>
                <!-- <div v-if="!props.row.workerId" class="caption" style="color:#ef4b46;">
                  Contractor
                </div> -->
                <!-- <div v-if="!props.row.employeeId" class="caption" >
                  <div style="color:#ef4b46;">Employee</div>
                  
                </div> -->
                <div v-if="props.row.employeeId && !props.row.employeeOnboardingComplete" class="caption">
                  <div style="color:#f0ad4e;">Employee</div>
                  
                </div>
                <div v-if="props.row.employeeId && props.row.employeeOnboardingComplete" class="caption">
                  <div style="color:#00C897;">Employee</div>

                </div>
              </span>
              <span v-else-if="props.column.field == 'phone'">
                <span v-if="props.row.phone" class="caption">
                  <a :href="'sms:' + props.row.phone" class="darkLink">{{props.row.phone}}</a>
                </span>
              </span>
              <span v-else-if="props.column.field == 'city'">
                <span class="caption" v-if="props.row.address && props.row.address.city">{{props.row.address.city}}</span>

                <div class="mt-0" v-if="!props.row.address || Object.keys(props.row.address).length == 0">
                  <button class="btn btn__xsmall btn__outlined mt-2" @click="onTextAddress(props.row)">
                    Send Reminder
                    <transition name="fade">
                      <span class="ml-2" v-if="performingRequestAddress">
                      <i class="fa fa-spinner fa-spin"></i>
                      </span>
                    </transition>
                  </button>
                </div>
                <div class="mb-0 mt-2 caption" v-if="props.row.updateAddressReminderSent">
                    Reminder Sent: {{formatStamp(props.row.updateAddressReminderSent)}}
                </div>

              </span>
              <span v-else-if="props.column.field == 'state'">
                <span class="caption" v-if="props.row.address && props.row.address.state">{{props.row.address.state}}</span>
              </span>
              <span v-else-if="props.column.field == 'link'">
                <router-link :to="`/users/` + props.row.id" target="_blank">
                  <i class="fas fa-external-link ml-3 mr-3"></i>
                </router-link>
              </span>
              <span v-else-if="props.column.field == 'skills'">
                <span v-if="!props.row.skills || props.row.skills.length == 0">
                  <i class="fad fa-briefcase ml-2 mr-2" style="opacity:50%;"></i>
                </span>
                <span v-if="props.row.skills && props.row.skills.length > 0">
                  <v-popover>
                    <i class="fad fa-briefcase ml-2 mr-2 success"></i>
                    <template slot="popover">
                      <span v-for="z in props.row.skills">{{z.title}} / </span>
                    </template>
                  </v-popover>
                </span>
              </span>
              <span v-else-if="props.column.field == 'blacklist'">
                <span v-if="props.row.blacklist && props.row.blacklist.length >=1" style="display:inline;">
                  <v-popover>
                    <i class="fas fa-exclamation-triangle ml-2 mr-2" style="color:red;"></i>
                    <template slot="popover">
                    <span v-for="z in props.row.blacklist">{{z.title}}</span>
                  </template>
                  </v-popover>
                </span>
              </span>
              
              <!-- <span v-else-if="props.column.field == 'touchLog'">
                <div class="caption" v-if="props.row.touchLog && props.row.touchLog[0]">

                  <div>{{findLast(props.row.touchLog).method}} - {{formatLast(props.row.touchLog)}}
                  </div>
    
                  <div>{{findLast(props.row.touchLog).contactedByFirstName}} {{findLast(props.row.touchLog).contactedByLastName}}</div>
                  <div>{{findLast(props.row.touchLog).notes}}</div>
                  
                </div>
              </span> -->

              <span v-else-if="props.column.field == 'pipelineAction'">
                <span v-if="action && action == 'addPipeline'">
                  <div style="width:calc(180px + 1.5em); padding: 0.75em 0.75em 0.75em 0.75em;">
                    <div class="mb-3" v-if="props.row.cancellationReason">{{props.row.cancellationReason}}</div>
                    <button class="btn btn__outlined btn__small" @click="addBack(props.row)">Undo</button>
                    <div class="caption mt-2">(Add back to pipeline)</div>
                  </div>

                  <!-- <button class="icon" v-tooltip="'delete instance'" @click.prevent="deletePipe(props.row, props.index)">
                    <i class="fas fa-trash ml-2 mr-2"></i>
                  </button> -->
                </span>
                <span v-if="action && action == 'removePipeline'">
                  <div style="width: 240px;">
                    <div class="flex justify-space-between mb-3">
                      <div>
                        <button class="btn btn__primary btn__small mb-2" @click="addHired(props.row)">Hire Worker</button>
                      </div>
                      <div v-if="props.row.cancellationReason">
                        <button class="btn btn__danger btn__small mb-2" @click="deletePipe(props.row)">Decline</button>
                      </div>
                    </div>
                    <v-select
                    :disabled="cancelledPipe"
                      label="title" 
                      :options="cancellationReasons"
                      v-model="props.row.cancellationReason"
                      :id="props.row.id + `cancellationReason`"
                      @input="updateReason(props.row)"
                      >
                    </v-select>
                  </div>

                  <!-- <button class="icon" v-tooltip="'delete instance'" @click.prevent="deletePipe(props.row, props.index)">
                    <i class="fas fa-trash ml-2 mr-2"></i>
                  </button> -->
                </span>
              </span>

              
              
              <span v-else-if="props.column.field == 'action'">
                <span v-if="action && action == 'removeUser'" style="width: 84px;">
                  <button class="icon" v-tooltip="'delete instance'" @click.prevent="deleteUser(props.row, props.index)">
                    <i class="fas fa-trash ml-2 mr-2"></i>
                  </button>
                </span>
          

                <span v-if="action && action == 'removePipeline'" style="width: 84px;">
                  <button class="btn btn__small btn__danger" @click="deleteInstance(props.row, props.index)">
                    Delete
                  </button>
                </span>
                
                <span v-if="action && action == 'applicants'" style="width: 84px;">
                  <button class="icon" v-tooltip="'accept application'" @click.prevent="acceptApplication(props.row, props.index)">
                    <i class="fa-solid fa-circle-check mr-3" style="color: green"></i>
                  </button>

                  <button class="icon" v-tooltip="'hold application'" @click.prevent="holdApplication(props.row, props.index)">
                    <i class="fa-solid fa-triangle-exclamation mr-3" style="color: goldenrod"></i>
                  </button>


                  <button class="icon" v-tooltip="'delete user'" @click.prevent="deleteApplication(props.row, props.index)">
                    <i class="fa-solid fa-octagon-exclamation" style="color: red"></i>
                  </button>
                  
                </span>
              </span>

              <span v-else-if="props.column.field == 'shiftPreference'" style="padding:0;">

                <div class="caption" v-if="props.row.shiftPreference" style="min-width:160px;">

                  <div v-for="(item, index) in props.row.shiftPreference" :key="index">
                    <span v-if="item.title">{{item.title}}</span>
                    <span v-else>{{item}}</span>
                  </div>
                    
                </div>

              </span>

              <span v-else-if="props.column.field == 'locationPreference'" style="padding:0;">
                <div class="caption" v-if="props.row.locationPreference" style="min-width:160px;">
                  {{props.row.locationPreference}}
                  
                </div>

              </span>


              


               <span v-else>
                <span class="caption">
                  {{props.formattedRow[props.column.field]}}
                </span>
              </span>
            </template>
      </vue-good-table>

  </div>
</template>

<style scoped>

  .cancelled  {
    background: #ff000029 !important;
  }
/*   .cancelled .vgt-inner-wrap .vgt-responsive .striped  {
    background: red;
  }

  .cancelled table.vgt-table{
    background: red !important;
  } */

  table.vgt-table {
    background-color: transparent !important;
}

  .v-select {

    border: 1px solid #ddd;
}

   table .vs__dropdown-toggle {
    background: white !important;
    border-radius: 0 !important;
    border: 1px solid #ddd;
    padding: 0.5rem 0.25rem;
    min-height: 36px;
}
.iconRow i {
  font-size: 1.4rem;
  margin-right: 1rem;
}
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import PipelineNote from '@/components/PipelineNote.vue'
import StarRating from 'vue-star-rating'
import EmployeeStatus from '@/components/EmployeeStatus.vue'
import InterviewStatus from '@/components/InterviewStatus.vue'
import ContractorStatus from '@/components/ContractorStatus.vue'
import ExportService from "@/services/ExportService"
import router from '@/router'
import * as moment from 'moment'
const fb = require('../firebaseConfig.js')

export default {
  props: ['group', 'groupUsers', 'action', 'showShiftPreference', 'cancelledPipe', 'eventShifts', 'eventId', 'isNewYork'],
  data: () => ({
    searchQuery: '',
    performingRequest: false,
    performingRequest2: false,
    performingRequest3: false,
    performingRequestDrug: false,
    performingRequestBack: false,
    performingRequestAccurate: false,
    performingRequestAddress: false,
    showTextBox: false,
    textMessage: '',
    batch: [],
    // exportBatch: [],
    activeItem: '',
    activeTitles: [],
    activePipelines:[],
    cancellationReasons: ['Not a Good Fit', 'No Longer Interested', 'Failed Background Check', 'Failed Drug Screening', 'Failed Accurate Check', 'Failed Amazon Check', 'Other'],
    columns: [
      {
        field: 'photoUrl',
        sortable: false,
        width:'84px',
        tdClass: 'text-center',
      },
      {
        label: 'Pipeline',
        field: 'pipelineAction',
        sortable: false,
        tdClass: 'pa-0'
      },
      
      {
        label: 'Name',
        field: 'fullName',
        sortable: false,
        width:'248px',
      },
      // {
      //   label: 'Last Touch',
      //   field: 'touchLog',
      //   sortable: false,
      //   width:'180px'
      // },
      // {
      //   label: '',
      //   field: 'watchlist',
      //   sortable: false,
      //   width:'40px',
      // },
      {
        label: '',
        field: 'notes',
        sortable: false,
        tdClass: 'pa-0'
      },
      // {
      //   label: 'Location',
      //   field: 'locationPreference',
      //   sortable: false,
      //   filterOptions: {
      //     enabled: true, // enable filter for this column
      //     placeholder: "No Filter", // placeholder for filter input
      //     filterValue: "", // initial populated value for this filter
      //     filterDropdownItems: ['New York, NY', 'Staten Island, NY', 'Brooklyn, NY', 'Queens, NY', 'Long Island, NY', 'The Bronx, NY'], // dropdown (with selected values) instead of text input
      //     // filterFn: this.columnFilterFn, //custom filter function that
      //     trigger: "enter" //only trigger on enter not on keyup
      //   }
      // },
      // {
      //   label: 'Position Preference',
      //   field: 'position',
      //   sortable: false,
      //   tdClass: 'pa-0'
      // },
      {
        label: 'Shift Preference',
        field: 'shift',

      },
      // {
      //   label: 'Shift Preference',
      //   field: 'shift',
      //   sortable: false,
      //   tdClass: 'pa-0'
      // },
      {
        label: 'Est. Start Date',
        field: 'estimatedStartDate',
        sortable: false,
        tdClass: 'pa-0'
      },
      {
        label: 'Onboarding',
        field: 'onboardFlow',
        sortable: false,
        width:'168px',
      },
      
      {
        label: 'Status',
        field: 'employmentStatus',
        sortable: false,
      },
      {
        label: 'Assignment',
        field: 'employmentAssignment',
      },
      {
        label: 'Payroll',
        field: 'onboardStatus',
        sortable: false,
        width:'88px',
      },
      {
        label: 'City',
        field: 'city',
        sortable: false,
        width:'128px',
      },
      {
        label: 'State',
        field: 'state',
        sortable: false,
        width:'40px',
      },
      {
        label: 'Joined',
        field: 'created',
        sortable: false,
        width:'88px',
      },
      {
        label: 'Phone',
        field: 'phone',
        sortable: false,
        width:'120px',
      },
      {
        label: 'Background',
        field: 'check',
        sortable: false,
        width:'148px',
      },
      {
        label: 'Drug',
        field: 'drug',
        sortable: false,
        width:'148px',
      },
      {
        label: 'Accurate',
        field: 'accurate',
        sortable: false,
        width:'148px',
      },
      // {
      //   label: 'Amazon',
      //   field: 'amazon',
      //   sortable: false,
      //   width:'140px',
      // },
      {
        label: 'Skills',
        field: 'skills',
        sortable: false,
        width:'40px',
      },
      {
        label: 'DNR',
        field: 'blacklist',
        width: '40px',
        sortable: false,
      },
      {
        label: 'Action',
        field: 'action',
        sortable: false,
      }
    ]
  }),
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    isPipeline() {
      return (this.$route.name == 'eventPipeline' || this.$route.name == 'myPipeline');
    },
    showLocation() {
      if (this.isNewYork || this.$route.name == 'newUsers') {
        return true
      } else {
        return false
      }
    }
  },
  components: {
    Loader,
    EmployeeStatus,
    StarRating,
    ContractorStatus,
    PipelineNote,
    InterviewStatus
  },
  methods: {
    async initialContact(row) {
      const date = new Date()
      let touch = {
        contactedById: this.userProfile.id,
        contactedByFirstName: this.userProfile.firstName,
        contactedByLastName: this.userProfile.lastName,
        method: "Initial Contact",
        notes: '',
        touched: date,
      }
      await this.$store.dispatch("addTouchLog", {
        userId: row.id,
        log: touch
      })

      await fb.usersCollection.doc(row.id).update({
        initialContactStatus: 'Contacted',
        updatedContactBy: this.userProfile.firstName + ' ' + this.userProfile.lastName,
        updatedContact: fb.firestore.FieldValue.serverTimestamp(),
      })
    },
    lastLoginTime(row) {
      if (row && row.logins && row.logins.length > 0) {
        // Assuming logins is an array of timestamps
        const lastTimestamp = row.logins[row.logins.length - 1];
        return moment.unix(lastTimestamp).format("MM/DD/YYYY hh:mm:ss a");
      } else {
      }
    },
    onTextDrug (row) {
      this.performingRequestDrug = true
      let newText = {
        name: row.firstName,
        phone: row.phone,
        userId: row.id,
        type: 'drugReminder'
      }
      this.$store.dispatch('updateDrugReminder', newText)
      setTimeout(() => {
          this.performingRequestDrug = false
      }, 1000)
    },
    onTextBack (row) {
      this.performingRequestBack = true
      let newText = {
        name: row.firstName,
        phone: row.phone,
        userId: row.id,
        type: 'backgroundReminder'
      }
      this.$store.dispatch('updateBackgroundReminder', newText)
      setTimeout(() => {
          this.performingRequestBack = false
      }, 1000)
    },
    onTextAccurate (row) {
      this.performingRequestAccurate = true
      let newText = {
        name: row.firstName,
        phone: row.phone,
        userId: row.id,
        type: 'accurateReminder'
      }
      this.$store.dispatch('updateAccurateReminder', newText)
      setTimeout(() => {
          this.performingRequestAccurate = false
      }, 1000)
    },
    addPipeline(row) {
      console.log(row)
      this.$store.dispatch("addToPipeline", {
        eventId: this.eventId,
        userId: row.id
      })
    },
    setSelected(value) {
      console.log(value)
    },
    async updateField(row, fieldName, value) {
      const updateObj = {}
      updateObj[fieldName] = value
      await fb.usersCollection.doc(row.id).update(updateObj)
    },
    async onTextReminder(row, type) {
      this[`performingRequest${type}`] = true
      const newText = {
        name: row.firstName,
        phone: row.phone,
        userId: row.id,
        type: `${type}Reminder`
      }
      await this.$store.dispatch(`update${type}Reminder`, newText)
      setTimeout(() => {
        this[`performingRequest${type}`] = false
      }, 1000)
    },
    showNote(r) {
      this.activeItem = r
    },
    closeNote(r) {
      this.activeItem = null
    },
    showBox() {
      this.showTextBox = true
    },
    hideBox() {
      this.showTextBox = false
    },
    selectionChanged(params) {
      this.batch = params.selectedRows
    },
    async sendTextMessage() {
      this.performingRequest3 = true
      const message = this.textMessage
      const messages = this.batch
        .filter(user => user.phone)
        .map(user => ({
          userId: user.id,
          phone: user.phone,
          message: message,
        }))
      await Promise.all(messages.map(msg => this.$store.dispatch('sendUserMessage', msg)))
      this.performingRequest3 = false
      this.textMessage = ''
      this.showTextBox = false
    },
    async makeHot(row) {

      await fb.usersCollection.doc(row.id).update({
        hot: true
      })
      row.hot = true
    },
    async makeCold(row) {
      console.log(row.id)
      await fb.usersCollection.doc(row.id).update({
        hot: false
      })
      row.hot = false
    },
    addWatch(row) {
      this.$store.dispatch('watchUser', row.id)
    },
    removeWatch(row) {
      this.$store.dispatch('unWatchUser', row.id)
    },
    isWatchList(row) {
      return this.userProfile && this.userProfile.userWatchList && this.userProfile.userWatchList.includes(row.id)
    },
    mySearchFn(row, col, cellValue, searchTerm) {
      if (searchTerm === "") return true;
      return row.firstName.toLowerCase().startsWith(searchTerm.toLowerCase()) || row.lastName.toLowerCase().startsWith(searchTerm.toLowerCase())
    },
    deleteInstance(item, index) {
      console.log(item)
      this.$store.dispatch("removeUserFromPipeline", {
        eventId: this.group.id,
        userId: item.id
      })
    },
    deleteUser(item, index) {
      this.$store.dispatch("removeUserFromGroup", {
        group: this.group,
        user: item
      })
    },
    updatePosition(row) {
      fb.usersCollection.doc(row.id).update({
        positionPreference: row.positionPreference
      })
    },
    updatePreference(row) {
      fb.usersCollection.doc(row.id).update({
        shiftPreference: row.shiftPreference
      })
    },
    addBack(item, index) {
      this.$store.dispatch("addBackToPipeline", {
        userId: item.id,
        eventId: this.group.id
      })
    },
    updateReason(item, index) {
      this.$store.dispatch("updateReason", {
        user: item,
      })
    },
    addHired(item, index) {
      this.$store.dispatch("hireFromPipeline", {
        userId: item.id,
        eventId: this.group.id
      })
    },
    deletePipe(item, index) {
      console.log(item)
      const order = item.pipeline[0]
      fb.usersCollection.doc(item.id).update({
        cancellationReason: item.cancellationReason
      })
      this.$store.dispatch("removeFromPipeline", {
        userId: item.id || null,
        eventId: order
      })
    },
    acceptApplication(item, index) {
      this.$store.dispatch("acceptUserToGroup", {
        user: item,
        group: this.group
      })
    },
    holdApplication(item, index) {
      this.$store.dispatch("waitlistUserToGroup", {
        user: item,
        group: this.group
      })
    },
    deleteApplication(item, index) {
      this.$store.dispatch("removeUserApplicationFromGroup", {
        group: this.group,
        user: item
      })
    },
    formatDate(q) {
      if (q) {
        const postedDate = new Date(q.seconds * 1000);
        return moment(postedDate).format('MM/DD/YYYY')
      } else {
        return null
      }
    },
    isMenuShown(params) {
      return groupName(params)
    },
    clearPopover() {
      return this.activeTitles = []
    },
    clearPipover() {
      return this.activePipelines = []
    },
    async showPopover(params) {
      this.activeTitles = await Promise.all(params.map(async group => {
        const doc = await fb.groupsCollection.doc(group).get()
        return doc.data().title
      }))
    },
    async showPipelines(params){
      this.activePipelines = await Promise.all(params.map(async order => {
        const doc = await fb.eventsCollection.doc(order).get()
        return doc.data().title
      }))
    },
    findLast(touchLog) {
      return touchLog[touchLog.length - 1]
    },
    formatStamp(stamp) {
      if (stamp) {
        const postedDate = new Date(stamp.seconds * 1000);
        return moment(postedDate).format('MM/DD/YYYY')
      }
    },
    formatLast(touchLog) {
      const last = this.findLast(touchLog)
      if (last && last.touched) {
        const postedDate = new Date(last.touched.seconds * 1000);
        return moment(postedDate).format('MM/DD/YYYY')
      } else {
        return null
      }
    },
    updatePipe(row) {
      console.log(row)
      fb.usersCollection.doc(row.id).update({
        estimatedStartDate: row.estimatedStartDate
      })
    },
    exportSelected() {
      const exportHeaders = [
        "First Name",
        "Last Name",
        "Email",
        "Phone",
        "City",
        "State"
      ]
      const exportItems = this.batch.map(user => [
        user.firstName || null,
        user.lastName || null,
        user.email || null,
        user.phone || null,
        user.address.city,
        user.address.state,
      ])
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, exportItems, gapi);
        exportService.export();
      });
    },

  // methods: {
    
  //   setSelected(value) {
  //     console.log(value)
  //   },
    

    


  //   eventLookup(pipeline) {
  //     console.log(pipeline[0])
  //     return fb.eventsCollection.doc(pipeline[0]).get().then(doc => {
  //       // console.log(doc.data().title)
  //       // Promise.resolve(doc.data().title)
  //       return doc.data().title
  //     })
  //   },
  //   onTextDrug (row) {
  //     this.performingRequestDrug = true
  //     let newText = {
  //       name: row.firstName,
  //       phone: row.phone,
  //       userId: row.id,
  //       type: 'drugReminder'
  //     }
  //     this.$store.dispatch('updateDrugReminder', newText)
  //     setTimeout(() => {
  //         this.performingRequestDrug = false
  //     }, 1000)
  //   },
  //   onTextBack (row) {
  //     this.performingRequestBack = true
  //     let newText = {
  //       name: row.firstName,
  //       phone: row.phone,
  //       userId: row.id,
  //       type: 'backgroundReminder'
  //     }
  //     this.$store.dispatch('updateBackgroundReminder', newText)
  //     setTimeout(() => {
  //         this.performingRequestBack = false
  //     }, 1000)
  //   },
  //   onTextAddress (row) {
  //     this.performingRequestAddress = true
  //     let newText = {
  //       name: row.firstName,
  //       phone: row.phone,
  //       userId: row.id,
  //       type: 'addressReminder'
  //     }
  //     this.$store.dispatch('updateAddressReminder', newText)
  //     setTimeout(() => {
  //         this.performingRequestAddress = false
  //     }, 1000)
  //   },
  //   onTextAccurate (row) {
  //     this.performingRequestAccurate = true
  //     let newText = {
  //       name: row.firstName,
  //       phone: row.phone,
  //       userId: row.id,
  //       type: 'accurateReminder'
  //     }
  //     this.$store.dispatch('updateAccurateReminder', newText)
  //     setTimeout(() => {
  //         this.performingRequestAccurate = false
  //     }, 1000)
  //   },
  //   showNote(r) {
  //     this.activeItem = r
  //   },
  //   closeNote(r) {
  //     this.activeItem = null
  //   },
  //   showBox() {
  //     this.showTextBox = true
  //   },
  //   hideBox() {
  //     this.showTextBox = false
  //   },
  //   selectionChanged(params) {
  //     this.batch = params.selectedRows
  //   },
  //   async sendTextMessage () {
  //     this.performingRequest3 = true
  //     let message = this.textMessage
  //     await this.batch.forEach(user => {
  //       console.log(user)
  //       if (user.phone) {
  //         let newMessage = {
  //           userId: user.id,
  //           phone: user.phone,
  //           message: message,
  //         }
  //         console.log(newMessage)
  //         this.$store.dispatch('sendUserMessage', newMessage)
  //       } 
  //     })
  //     this.performingRequest3 = false
  //     this.textMessage = ''
  //     this.showTextBox = false
  //   },
  //   addWatch(row) {
  //     this.$store.dispatch('watchUser', row.id)
  //   },
  //   removeWatch(row) {
  //     // let groupUsers = this.groupUsers
  //     // groupUsers.splice(index, 1)
  //     this.$store.dispatch('unWatchUser', row.id)
  //   },
  //   isWatchList(row) {
  //     if (this.userProfile && this.userProfile.userWatchList && this.userProfile.userWatchList.length >= 1) {
  //       return this.userProfile.userWatchList.includes(row.id)
  //     } else {
  //       return false
  //     }
  //   },
  //   mySearchFn(row, col, cellValue, searchTerm) {
  //     if (searchTerm === "") return true;
  //     return row.firstName.toLowerCase().startsWith(searchTerm.toLowerCase()) || row.lastName.toLowerCase().startsWith(searchTerm.toLowerCase())
  //   },    
  //   deleteUser(item, index) {
  //     console.log(item)
  //     let groupUsers = this.groupUsers
  //     groupUsers.splice(index, 1)
  //     this.$store.dispatch("removeUserFromGroup", {
  //       group: this.group,
  //       user: item
  //     })
  //     // this.$store.dispatch("getGroupFromId", this.$route.params.id);
  //   },
  //   updatePreference(row) {
  //     fb.usersCollection.doc(row.id).update({
  //       shiftPreference: row.shiftPreference
  //     })
  //   },
  //   addBack(item, index) {
  //     this.$store.dispatch("addBackToPipeline", {
  //       userId: item.id,
  //       eventId: this.group.id
  //     })
  //   },
  //   addHired(item, index) {
  //     this.$store.dispatch("hireFromPipeline", {
  //       userId: item.id,
  //       eventId: this.group.id
  //     })
  //   },
  //   async deletePipe(item, index) {
  //     console.log(item)
  //     let order
  //     await fb.usersCollection.doc(item.id).update({
  //       cancellationReason: item.cancellationReason
  //     })
  //     let groupUsers = this.groupUsers
  //     if (item && item.pipelineOrder && item.pipelineOrder.id) {
  //       order = item.pipelineOrder.id
  //     } else {
  //       order = null
  //     }
  //     groupUsers.splice(index, 1)
  //     this.$store.dispatch("removeFromPipeline", {
  //       userId: item.id || null,
  //       eventId: order
        
  //     })
  //     // this.$store.dispatch("getGroupFromId", this.$route.params.id);
  //   },
  //   acceptApplication(item, index) {
  //     console.log(item)
  //     console.log(index)
  //     this.$store.dispatch("acceptUserToGroup", {
  //       user: item,
  //       group: this.group
  //     })
  //     let groupApplications = this.groupUsers
  //     groupApplications.splice(index, 1)
  //   },
  //   holdApplication(item, index) {
  //     this.$store.dispatch("waitlistUserToGroup", {
  //       user: item,
  //       group: this.group
  //     })
  //     let groupApplications = this.groupUsers
  //     groupApplications.splice(index, 1)
  //   },
  //   deleteApplication(item, index) {
  //     this.$store.dispatch("removeUserApplicationFromGroup", {
  //       group: this.group,
  //       user: item
  //     })
  //     let groupApplications = this.groupUsers
  //     groupApplications.splice(index, 1)
  //   },
  //   formatDate(q) {
  //     if(q) {
  //       const postedDate = new Date(q.seconds) * 1000;
  //       return moment(postedDate).format('MM/DD/YYYY')
  //     } else {
  //       return null
  //     }
  //   },
  //   isMenuShown(params) {
  //     console.log(params)
  //     return groupName(params)
  //   },
  //   clearPopover() {
  //     return this.activeTitles = []
  //   },
  //   showPopover(params){
  //     console.log(params)
  //     params.forEach(group => {
  //       fb.groupsCollection.doc(group).get()
  //       .then(doc => {
  //         this.activeTitles.push(doc.data().title)
  //       })
  //     })
  //   },
  //   // formatDate(q) {
  //   //   if(q) {
  //   //     const postedDate = new Date(q.seconds) * 1000;
  //   //     return moment(postedDate).format('hh:mm:ss A')
  //   //   } else {
  //   //     return null
  //   //   }
  //   // },
  //   findLast(touchLog) {
  //     return touchLog[touchLog.length - 1]
  //   },
  //   formatStamp(stamp) {
  //     if(stamp) {
  //       const postedDate = new Date(stamp.seconds) * 1000;
  //       return moment(postedDate).format('MM/DD/YYYY')
  //     }
  //   },
  //   formatLast(touchLog) {
  //     let last = touchLog[touchLog.length - 1]
  //     console.log(last)
  //     if (last.touched) {
  //       console.log(last.touched.seconds)
  //       const postedDate = new Date(last.touched.seconds) * 1000;
  //       console.log(postedDate)
  //       return moment(postedDate).format('MM/DD/YYYY')
  //     } else {
  //       return null
  //     }
  //   },
  //   exportSelected() {
  //     const exportHeaders = [
  //       "First Name",
  //       "Last Name",
  //       "Email",
  //       "Phone",
  //       "City",
  //       "State"
  //     ]
  //     const exportItems = [];
  //     for (var key in this.batch) {
  //       exportItems.push([
  //         this.batch[key].firstName || null,
  //         this.batch[key].lastName || null,
  //         this.batch[key].email || null,
  //         this.batch[key].phone || null,
  //         this.batch[key].address.city,
  //         this.batch[key].address.state,
  //       ])
  //     }
  //     this.$gapi.getGapiClient().then(gapi => {
  //       const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
  //       exportService.export();
  //     });
  //   },
  },
  beforeDestroy () {
    this.searchQuery = null
    delete this.searchQuery
    this.payBatch = null
    delete this.payBatch
    this.batch = null
    delete this.batch
    this.activeItem = ''
    delete this.activeItem
    this.activeTitles = null
    delete this.activeTitles
    this.showTextBox = ''
    delete this.showTextBox
    this.columns = null
    delete this.columns
    this.performingRequest = null
    delete this.performingRequest
    this.performingRequest2 = null
    delete this.performingRequest2
    this.performingRequest3 = null
    delete this.performingRequest3
    this.performingRequestDrug = null
    delete this.performingRequestDrug
    this.performingRequestBack = null
    delete this.performingRequestBack
    this.performingRequestAccurate = null
    delete this.performingRequestAccurate
    this.performingRequestAddress = null
    delete this.performingRequestAddress
    this.textMessage = null
    delete this.textMessage
    this.cancellationReasons = null
    delete this.cancellationReasons
  }
}
</script>